import axios from 'axios';

const createAuthAxios = () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': (window as any).validationToken,
    },
  };

  const authorizedAxios = axios.create({
    ...config,
  });

  return authorizedAxios;
};

export default createAuthAxios;
